<template>
  <div class="table">
    <a-spin tip="Loading..." :spinning="loading" size="large">
      <div class="scroll" v-html="data">{{ data }}</div>
      <div class="btnWrap">
        <a-button
          type="primary"
          shape="round"
          size="large"
          block
          class="btn"
          @click="toDownload()"
        >
          下载登记表
        </a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { tableHtml, downloadInfo } from "@/api/form";

export default {
  data() {
    return {
      data: "",
      type: "1", //  1 信息登记表   2  民办学校选报登记表
      loading: false,
    };
  },
  methods: {
    getTable() {
      this.loading = true;
      tableHtml({
        fjType: this.type,
      }).then((res) => {
        this.loading = false;
        this.data = res.data;
      });
    },
    toDownload() {
      downloadInfo({
        fjType: this.type,
      }).then((res) => {
        if (res.code == 0) {
          window.open(
            `/fast-imagine/mobile/yw/exportDjb?fjType=${
              this.type
            }&userCode=${localStorage.getItem("userCode")}`
          );
          this.$emit("reload");
        }
      });
    },
  },
  mounted() {
    this.getTable();
  },
};
</script>

<style lang="less" scoped>
.table {
  padding: 16px 16px 0;
  .btnWrap {
    text-align: center;
  }
  .btn {
    margin: 32px auto 16px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
.scroll {
  width: 100%;
  min-height: 448px;
  overflow: scroll;
}
/deep/ caption {
  text-align: center;
  caption-side: top;
  color: #333;
}
</style>
