<template>
  <div class="detail">
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          学生信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">姓名：</div>
          <div class="itemContent">{{ detailInfo.xm }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">性别：</div>
          <div class="itemContent">{{ detailInfo.xb == 1 ? "男" : "女" }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">{{ zjlx }}：</div>
          <div class="itemContent">{{ detailInfo.zjh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">出生日期：</div>
          <div class="itemContent" v-if="detailInfo.csrq">
            {{ detailInfo.csrq | dateFilter }}
          </div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">意向学校：</div>
          <div class="itemContent">吕梁一小</div>
        </div>
      </div>
    </div>
    <div class="birth" v-if="glyhFlag">
      <span style="color: red"
        >检测到您入学报名儿童可能是多胞胎,且选报了同一所公办学校,请确定是否进行多胞胎关联派位</span
      >
      <div class="checkbox" v-for="(item, index) in childrens" :key="index">
        <a-checkbox v-model="item.checked" @change="onChange(item)">
          {{ item.stuXm }} {{ item.stuZjh }} {{ item.bzInfo }}
        </a-checkbox>
      </div>
      <span style="color: red"
        >重要提示:您勾选关联多胞胎后,多人将视为一人参加派位,,成功则多人均被录取,未成功则多人均未被录取;若
        不勾选,则视为多人分别独立参加派位。
      </span>
    </div>
    <div class="prompt" :style="{ height }">
      您确定是否符合报名条件，报名后将进行资格验证，符合条件的将进行摇号方式录取。
    </div>
    <div class="application" @click="application">报名</div>
  </div>
</template>

<script>
import moment from "moment";
import { getDetail, getIfGlyh } from "@/api/form";
import { getListBySignList, getintentionsubmit } from "@/api/api";

export default {
  data() {
    return {
      imgOrigin: this.$store.state.fileUrl,
      detailInfo: {},
      previewVisible: false, // 图片预览
      imgUrl: "",
      systemAreaCode: localStorage.getItem("systemAreaCode"),
      zjlx: "",
      glyhFlag: false,
      height: "",
      childrens: [
        // {
        //   stuXm: "刘妍慧",
        //   stuZjh: "141102201205281138",
        //   bzInfo: "已关联（侯姝妤）",
        //   stuGlIds: "113,92",
        //   regId: "92",
        // },
        // {
        //   stuXm: "刘妍慧",
        //   stuZjh: "141102201205281138",
        //   bzInfo: "已关联（侯姝妤）",
        //   stuGlIds: "645,02",
        //   regId: "92",
        // },
        // {
        //   stuXm: "刘232慧",
        //   stuZjh: "1411021221201205281138",
        //   bzInfo: "已关联（侯姝妤）",
        //   stuGlIds: "117,8,9",
        //   regId: "92",
        // },
      ],
      boundIds: [],
      stuGlIds: [],
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  methods: {
    onChange(item) {
      if (item.checked == true) {
        this.stuGlIds.push(item.stuGlIds);
      } else {
        this.stuGlIds = this.stuGlIds.filter((i) => i !== item.stuGlIds);
      }
      this.stuGlIds = Array.from(new Set(this.stuGlIds));
      this.boundIds = this.stuGlIds.map((str) => str.split(",")).flat();
      console.log(this.boundIds, "12,");
    },
    application() {
      getintentionsubmit({
        planId: this.detailInfo.planId,
        boundIds: this.boundIds,
      }).then((res) => {
        if (res) {
          this.$message.success("提交成功");
        } else {
          this.$message.error("提交失败");
        }
        console.log(res, "424");
      });
      setTimeout(() => {
        this.$emit("curStep");
      }, 2000);
    },

    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
        getIfGlyh({
          yxSchoolId: "122",
        }).then((res) => {
          if (res.data.length > 0) {
            this.glyhFlag = true;
            this.height = "68px";
            this.childrens = res.data;
          } else {
            this.glyhFlag = false;
          }
        });
      });
    },

    getDic() {
      getListBySignList({
        types: 804001,
      }).then((res) => {
        console.log(res.data[0].dicts, "10,");
        res.data[0].dicts.forEach((item) => {
          if (item.value == this.detailInfo.zjlx) {
            this.zjlx = item.name;
          }
          console.log(this.zjlx, "99,");
        });
      });
    },
  },
  mounted() {
    this.getDetailInfo();
    setTimeout(() => {
      this.getDic();
    }, 50);
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding: 16px 16px 0;
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
}
.card {
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    flex-wrap: wrap;
  }
  .familyItem {
    width: 100%;
    .num {
      font-size: 14px;
      font-weight: 600;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .detailItem {
    display: flex;
    width: 49%;
    font-size: 14px;
    margin-right: 1%;
    margin-bottom: 16px;
  }
  .file {
    width: 100%;
    .itemContent {
      display: flex;
      flex-wrap: wrap;
    }
    .img {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    img {
      width: 68px;
      height: 68px;
    }
  }
  .itemTitle {
    color: #666;
  }
  .itemContent {
    color: #333;
  }
}
.birth {
  width: 100%;
  border: 2px dashed #000;
  padding: 4px;
  .checkbox {
    padding: 10px 10px;
  }
}
.prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  margin-top: 10px;
  background-color: #e6e6e65e;
}
.application {
  width: 16%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 20px auto;
  border-radius: 10px;
  background-color: orange;
  cursor: pointer;
}
/deep/.ant-checkbox-inner {
  border-radius: 50%;
}
</style>
