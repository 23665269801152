<template>
  <div class="page">
    <home-header activeIndex="0" :showNav="false" pageTitle="报名流程" />
    <div class="wrap">
      <!-- 暂无结果 -->
      <div
        class="result"
        v-if="regStatus == 1 || regStatus == 4 || regStatus == 5"
      >
        <img class="img" :src="require('@/assets/home/noCheck.png')" alt="" />
        <div class="desc">
          暂无结果 <br />
          请耐心等待校验结果
        </div>
      </div>
      <!-- 未通过 -->
      <div class="result" v-if="regStatus == 3">
        <img class="img" :src="require('@/assets/home/error.png')" alt="" />
        <div class="desc">您的信息校验未通过</div>
        <div class="red" v-if="regBz">{{ regBz }}</div>
        <a-button
          type="primary"
          shape="round"
          size="large"
          block
          class="btn"
          @click="toForm"
          :disabled="count == 0"
          v-if="xgRegisterFlag"
          >修改注册信息</a-button
        >
        <div class="red" v-if="xgRegisterFlag">
          您还剩 {{ count }} 次修改机会，请认真填写信息！
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";
import { btnKg, changeCount, getStatus } from "@/api/api";
import { getFlag } from "@/api/common";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
  },
  data() {
    return {
      regStatus: "", // 核验结果
      regBz: "", // 备注
      xgRegisterFlag: false, // 注册信息修改开关
      count: 0,
    };
  },
  methods: {
    // 注册信息修改开关
    xgFlag() {
      getFlag({
        paramKey: "registerXg",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          // this.xgRegisterFlag = true;
          this.getCount(); // 注册信息修改剩余次数
        } else {
          this.xgRegisterFlag = false;
        }
      });
    },
    // 注册信息修改剩余次数
    getCount() {
      changeCount().then((res) => {
        if (res.data > 0) {
          this.xgRegisterFlag = true;
        } else {
          this.xgRegisterFlag = false;
        }
        this.count = res.data;
      });
    },
    // 再次填报
    toForm() {
      btnKg({
        bmStageId: localStorage.getItem("bmStageId"),
      }).then((res) => {
        if (res.code == 0) {
          this.$router.replace({
            path: "/fillAgain",
            query: {},
          });
        } else {
          this.$message.error(res.description);
        }
      });
    },
  },
  mounted() {
    getStatus().then((res) => {
      // regStatus  注册状态（1  待审核  2 注册成功 3退回）
      this.regStatus = res.data.regStatus;
      this.regBz = res.data.regBz;
      if (res.data.regStatus == 2) {
        this.$router.replace({
          path: "/home",
        });
      }
      if (this.regStatus == 3) {
        this.xgFlag();
      }
    });

    this.regStatus = this.$route.query.regStatus;
    this.regBz = this.$route.query.regBz;
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1000px;
  height: 444px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
}
.result {
  width: 440px;
  margin: 0 auto;
  text-align: center;
  padding-top: 78px;
  .img {
    // width: 150px;
    // height: 164px;
    margin-bottom: 24px;
  }
  .desc {
    font-size: 14px;
    color: #999;
  }
  .red {
    color: #ff5757;
    margin-top: 16px;
  }
  .btn {
    margin-top: 42px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
</style>
