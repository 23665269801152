import { axios } from '@/utils/request'

// 开关
export function getFlag (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/getConfig',
    method: 'get',
    params: params
  })
}

// 获取行政区划
export function getCity (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/region/all',
    method: 'get',
    data: params
  })
}

// 批量获取字典  types   schoolCode
export function getDicAll (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/dicList/ByCodes',
    method: 'get',
    params: params
  })
}

// 户主页识别
export function hkbSb (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/hukoubenSb',
    method: 'get',
    params: params
  })
}

// 不动产证识别
export function fczSb (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/fczSb',
    method: 'get',
    params: params
  })
}

// 出生证识别
export function cszmSb (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/cszmSb',
    method: 'get',
    params: params
  })
}

