<template>
  <div class="detail">
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          学生信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">姓名：</div>
          <div class="itemContent">{{ detailInfo.xm }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">性别：</div>
          <div class="itemContent">{{ detailInfo.xb == 1 ? "男" : "女" }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">{{ zjlx }}：</div>
          <div class="itemContent">{{ detailInfo.zjh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">出生日期：</div>
          <div class="itemContent" v-if="detailInfo.csrq">
            {{ detailInfo.csrq | dateFilter }}
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          户籍信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">户籍地：</div>
          <div class="itemContent">
            {{ detailInfo.hjProvinceText }} {{ detailInfo.hjCityText }}
            {{ detailInfo.hjCountyText }}
          </div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">户籍登记详细地址：</div>
          <div class="itemContent">{{ detailInfo.hjAddress }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">户籍所在地派出所：</div>
          <div class="itemContent">{{ detailInfo.hjSzdpcs }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">户口登记（迁入）日期：</div>
          <div class="itemContent" v-if="detailInfo.hjDjrq">
            {{ detailInfo.hjDjrq | dateFilter }}
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.hkbUrl">
          <div class="itemTitle">户口簿首页附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.hkbUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.hkbHzUrl">
          <div class="itemTitle">户口簿户主页附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.hkbHzUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.hkbJhrUrl">
          <div class="itemTitle">户口簿监护人页附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.hkbJhrUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.hkbXsUrl">
          <div class="itemTitle">户口簿学生页附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.hkbXsUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          监护人信息
        </div>
      </div>
      <div class="card family">
        <div class="familyItem" v-for="(item, idx) in familyList" :key="idx">
          <div class="num">监护人({{ idx + 1 }}）</div>
          <div class="item">
            <div class="detailItem">
              <div class="itemTitle">监护人姓名：</div>
              <div class="itemContent">{{ item.fXm }}</div>
            </div>
            <div class="detailItem">
              <div class="itemTitle">与适龄儿童关系：</div>
              <div class="itemContent">{{ item.gx }}</div>
            </div>
            <div class="detailItem">
              <div class="itemTitle">身份证号：</div>
              <div class="itemContent">{{ item.sfzh }}</div>
            </div>
            <div class="detailItem">
              <div class="itemTitle">手机号：</div>
              <div class="itemContent">{{ item.lxdh }}</div>
            </div>
            <div class="detailItem">
              <div class="itemTitle">工作单位类型：</div>
              <div class="itemContent">{{ item.dwType }}</div>
            </div>
            <!-- <div class="detailItem">
              <div class="itemTitle">工作单位：</div>
              <div class="itemContent">{{item.gzdw}}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="detailInfo.fcYw == 1">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          房产信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">证件类型：</div>
          <div class="itemContent">{{ detailInfo.fczjLx }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">房产所有权人姓名：</div>
          <div class="itemContent">{{ detailInfo.fczjSry }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">房产地址所在街道(乡镇)：</div>
          <div class="itemContent">{{ detailInfo.fcStreet }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">房产地址所在社区(村)：</div>
          <div class="itemContent">{{ detailInfo.fcCommunity }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">
            房产登记详细地址：{{ detailInfo.fcAddress }}
          </div>
          <!-- <div class="itemContent">{{ detailInfo.fcAddress }}</div> -->
        </div>
        <div class="detailItem">
          <div class="itemTitle">房产证件合同编号：</div>
          <div class="itemContent">{{ detailInfo.fczjBh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">房产证件与学生关系：</div>
          <div class="itemContent">{{ detailInfo.fczjYxxgx }}</div>
        </div>
        <div class="detailItem file" v-if="detailInfo.fczjUrl">
          <div class="itemTitle">房产证明材料：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.fczjUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="detailInfo.jzzYw == 1">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          居住证信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">持证人姓名：</div>
          <div class="itemContent">{{ detailInfo.jzzCry }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住证编号：</div>
          <div class="itemContent">{{ detailInfo.jzzBh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住证发证机关：</div>
          <div class="itemContent">{{ detailInfo.jzzFzjg }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住证所属街道：</div>
          <div class="itemContent">{{ detailInfo.jzzStreet }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住证所属社区(村)：</div>
          <div class="itemContent">{{ detailInfo.jzzCommunity }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">居住证详细地址：</div>
          <div class="itemContent">{{ detailInfo.jzzAddress }}</div>
        </div>
        <div class="detailItem file" v-if="detailInfo.jzzUrl">
          <div class="itemTitle">居住证附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.jzzUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="detailInfo.yyzzYw == 1">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          营业执照信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem">
          <div class="itemTitle">营业执照法人：</div>
          <div class="itemContent">{{ detailInfo.yyzzFr }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">营业执照注册时间：</div>
          <div class="itemContent" v-if="detailInfo.yyzzZcsj">
            {{ detailInfo.yyzzZcsj | dateFilter }}
          </div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">营业执照编号：</div>
          <div class="itemContent">{{ detailInfo.yyzzBh }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">营业执照登记详细地址：</div>
          <div class="itemContent">{{ detailInfo.yyzzDjAddress }}</div>
        </div>
        <div class="detailItem">
          <div class="itemTitle">营业执照是否年检：</div>
          <div class="itemContent">
            {{ detailInfo.yyzzSfnj == 1 ? "是" : "否" }}
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.yyzzUrl">
          <div class="itemTitle">居住证附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.yyzzUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="detailInfo.jhzUrl || detailInfo.cszUrl">
      <div class="part">
        <div class="title">
          <div class="line"></div>
          其他信息
        </div>
      </div>
      <div class="card">
        <div class="detailItem file" v-if="detailInfo.jhzUrl">
          <div class="itemTitle">结婚证附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.jhzUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
        <div class="detailItem file" v-if="detailInfo.cszUrl">
          <div class="itemTitle">出生医学证明附件：</div>
          <div class="itemContent">
            <div
              class="img"
              v-for="(img, index) in detailInfo.cszUrl.split(',')"
              :key="index"
            >
              <img
                :src="imgOrigin + img"
                @click="previewImage(imgOrigin + img)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <a-modal
      :width="800"
      :visible="previewVisible"
      :footer="null"
      @cancel="imgHandleCancel"
    >
      <img alt="example" style="width: 100%" :src="imgUrl" />
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { getDetail } from "@/api/form";
import { getDicAll } from "@/api/common";

export default {
  data() {
    return {
      zjlxList: [], // 身份证件类型
      fczjlxList: [], // 房产证件类型
      gxList: [], // 关系列表
      fcgxList: [], // 房产关系列表
      dwTypeList: [], // 工作/就业单位类型
      familyList: [],
      imgOrigin: this.$store.state.fileUrl,
      detailInfo: {},
      dicList: [],
      previewVisible: false, // 图片预览
      imgUrl: "",
      zjlx: "",
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  methods: {
    previewImage(url) {
      this.previewVisible = true;
      this.imgUrl = url;
    },
    imgHandleCancel() {
      this.previewVisible = false;
    },
    getDic() {
      const _this = this;
      getDicAll({
        schoolCode: localStorage.getItem("schoolCode"),
        types: "804001,804002,804003,804006,804009",
      }).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.filter(function (item) {
              // 身份证件类型
              if (item.code == 804001) {
                _this.zjlxList = item.dicts;
              }
              // 房产证件类型
              if (item.code == 804002) {
                _this.fczjlxList = item.dicts;
              }
              // 监护人关系类型
              if (item.code == 804003) {
                _this.gxList = item.dicts;
              }
              // 房产关系类型
              if (item.code == 804009) {
                _this.fcgxList = item.dicts;
              }
              // 工作/就业单位类型
              if (item.code == 804006) {
                _this.dwTypeList = item.dicts;
              }
            });
          }
          this.getDetailInfo();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
        console.log(this.detailInfo, "918,");
        this.familyList = res.data.familyList;
        this.fcgxList.forEach((ele) => {
          if (ele.value == res.data.fczjYxxgx) {
            this.detailInfo.fczjYxxgx = ele.name;
          }
        });
        this.fczjlxList.forEach((ele) => {
          if (ele.value == res.data.fczjLx) {
            this.detailInfo.fczjLx = ele.name;
          }
        });

        this.zjlxList.forEach((item) => {
          if (item.value == this.detailInfo.zjlx) {
            this.zjlx = item.name;
          }
        });
        if (this.familyList.length > 0) {
          this.familyList.forEach((item, idx) => {
            this.gxList.forEach((dic, didex) => {
              if (dic.value == item.gx) {
                item.gx = dic.name;
              }
            });
            this.dwTypeList.forEach((dic, didex) => {
              if (dic.value == item.dwType) {
                item.dwType = dic.name;
              }
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getDic();
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding: 16px 16px 0;
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
}
.card {
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    flex-wrap: wrap;
  }
  .familyItem {
    width: 100%;
    .num {
      font-size: 14px;
      font-weight: 600;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .detailItem {
    display: flex;
    width: 44%;
    font-size: 14px;
    margin-right: 6%;
    margin-bottom: 16px;
  }
  .file {
    width: 100%;
    .itemContent {
      display: flex;
      flex-wrap: wrap;
    }
    .img {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    img {
      width: 68px;
      height: 68px;
    }
  }
  .itemTitle {
    color: #666;
  }
  .itemContent {
    color: #333;
  }
}
</style>
